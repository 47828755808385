$light-mode: rgb(230, 230, 230);
$dark-mode: rgb(34, 34, 34);
$cd-cover-back: rgb(155, 177, 59);

button {
  cursor: pointer;
}
.main-container {
  padding: 5px 5%;
  height: 100vh;
}

.light {
  color: $dark-mode;
  background-color: $light-mode;
  transition: background-color 0.9s ease;
}

.dark {
  color: $light-mode;
  background-color: $dark-mode;
  transition: background-color 0.9s ease;
}

///////////////
//// Header////
///////////////
.header-container {
  margin: 20px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}

.header-elements {
  max-width: 18rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.header-title {
  font-size: 1.5rem;
}

.dark-mode-switch {
  text-align: right;
}

.dark-mode-switch button {
  position: relative;
  height: 2.2rem;
  width: 4.2rem;
  border-radius: 1rem;
}

.btn-dark {
  background-color: $light-mode;
}

.dark-mode-switch button span {
  background-color: black;
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  border-radius: 50%;
  height: 1.7rem;
  width: 1.7rem;
}

.slide-end {
  transition: transform 0.3s ease;
  transform: translateX(2rem);
}

.slide-start {
  transition: transform 0.3s ease;
  transform: translateX(0);
}

///////////////
//// Card  ////
///////////////

.card-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}

.card {
  margin: 20px;
  position: relative;
  max-width: 18rem;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  border: 4px solid #fff;
  box-shadow: 0 6px 10px #212121;
  border-radius: 10px;
  transition: all 0.3s ease;
  background-size: 100%;
  opacity: 80%;
}

.card img {
  height: auto;
  width: calc(100% - 14px);

  border-radius: 50%;
  border: 7px solid white;
}

.rotate {
  animation: rotation 7s infinite linear;
}

.card .img-center-inner {
  position: absolute;
  width: 10%;
  height: 10%;
  top: 50%;
  left: 50%;
  border: 1rem solid rgb(96, 100, 75);
  border-radius: 50%;

  transform: translate(-50%, -50%);
  z-index: 1;
}

.card .img-center-outer {
  position: absolute;
  width: 11%;
  height: 11%;
  top: 50%;
  left: 50%;
  border: 1.1rem solid rgb(9, 10, 6);
  border-radius: 50%;
  background-color: rgb(48, 48, 48);
  transform: translate(-50%, -50%);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

///////////////
//// Label ////
///////////////
.label-container {
  display: grid;
  justify-items: center;
  font-size: 1.5rem;
}

///////////////
// Controls  //
///////////////
.controller-container {
  display: grid;
  justify-items: center;
}

.controllers {
  margin: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

///////////////
//// Timer ////
///////////////
.timer-container {
  display: grid;
  justify-items: center;
}

.form-section {
  max-width: 18rem;
  margin: 20px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1rem;
}

.form-section select {
  border-radius: 5px;
  border-width: 2px;
}

.form-section button {
  background-color: rgb(0, 236, 0);
  border-radius: 5px;
  color: rgb(31, 13, 13);
  font-weight: 600;
  box-shadow: 3px 5px rgb(4, 126, 0);
}

.time-countdown {
  font-size: 1rem;
  font-weight: 700;
  border: 2px solid black;
  max-width: 18rem;
  text-align: center;
}
